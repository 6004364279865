// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-101-jsx": () => import("./../../../src/pages/101.jsx" /* webpackChunkName: "component---src-pages-101-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-careers-internships-jsx": () => import("./../../../src/pages/careers/internships.jsx" /* webpackChunkName: "component---src-pages-careers-internships-jsx" */),
  "component---src-pages-comming-soon-jsx": () => import("./../../../src/pages/comming-soon.jsx" /* webpackChunkName: "component---src-pages-comming-soon-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-policy-jsx": () => import("./../../../src/pages/policy.jsx" /* webpackChunkName: "component---src-pages-policy-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-sustain-climate-change-jsx": () => import("./../../../src/pages/sustain/climate-change.jsx" /* webpackChunkName: "component---src-pages-sustain-climate-change-jsx" */),
  "component---src-pages-sustain-code-of-ethics-jsx": () => import("./../../../src/pages/sustain/code-of-ethics.jsx" /* webpackChunkName: "component---src-pages-sustain-code-of-ethics-jsx" */),
  "component---src-pages-sustain-human-rights-jsx": () => import("./../../../src/pages/sustain/human-rights.jsx" /* webpackChunkName: "component---src-pages-sustain-human-rights-jsx" */),
  "component---src-pages-sustain-index-jsx": () => import("./../../../src/pages/sustain/index.jsx" /* webpackChunkName: "component---src-pages-sustain-index-jsx" */),
  "component---src-pages-sustain-responsible-sourcing-jsx": () => import("./../../../src/pages/sustain/responsible-sourcing.jsx" /* webpackChunkName: "component---src-pages-sustain-responsible-sourcing-jsx" */),
  "component---src-pages-sustain-strategy-and-management-jsx": () => import("./../../../src/pages/sustain/strategy-and-management.jsx" /* webpackChunkName: "component---src-pages-sustain-strategy-and-management-jsx" */),
  "component---src-pages-sustain-sustain-strategy-jsx": () => import("./../../../src/pages/sustain/sustain-strategy.jsx" /* webpackChunkName: "component---src-pages-sustain-sustain-strategy-jsx" */),
  "component---src-pages-technology-advanced-materials-jsx": () => import("./../../../src/pages/technology/advanced-materials.jsx" /* webpackChunkName: "component---src-pages-technology-advanced-materials-jsx" */),
  "component---src-pages-technology-chantico-jsx": () => import("./../../../src/pages/technology/chantico.jsx" /* webpackChunkName: "component---src-pages-technology-chantico-jsx" */),
  "component---src-pages-technology-dep-jsx": () => import("./../../../src/pages/technology/dep.jsx" /* webpackChunkName: "component---src-pages-technology-dep-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../../../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */),
  "component---src-pages-technology-volta-jsx": () => import("./../../../src/pages/technology/volta.jsx" /* webpackChunkName: "component---src-pages-technology-volta-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

